/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  function configurer(){
    
  //Gestion menu
  /*$(window).on('scroll', function() {

    scrollPosition = $(this).scrollTop();
    if (scrollPosition >= 50) {
      $('#main-header').addClass('scrolled');
    }else {
      $('#main-header').removeClass('scrolled');
    }
  });*/


  $('.hero-slider').slick({
    fade: true,
    autoplay: true,
  });

  $('.avantages-slider').slick({
    arrows: true,
    dots: false,
    fade: true,
    adaptiveHeight: true
  });

  $('.questions-slider').slick({
    slidesToShow: 2.5,
    infinite: false,
    adaptiveHeight: true,
    appendArrows: $('.questions-slider-arrows'),    
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
        }
      }
    ],
  });

  $('.questions-slider .slide .slide-top .action').on('click touch', function() {
    $(this).parent().parent().slideUp();
    $(this).parent().parent().parent().find('.slide-bottom').slideDown();
  });
  $('.questions-slider .slide .slide-bottom .action').on('click touch', function() {
    $(this).parent().parent().parent().slideUp();
    $(this).parent().parent().parent().parent().find('.slide-top').slideDown();
  });

  $('#menu-trigger').on('click', function() {
    $(this).toggleClass('open');

    $('#mobile-nav').slideToggle();
    $('#main-header').toggleClass('menu-open');

  });



  $('.home-service-slider').slick({
    slidesToShow: 3.5,
    infinite: false,
    adaptiveHeight: true,
    appendArrows: $('.home-service-slider-nav'),   
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2.5,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1.5,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.5,
        }
      },
      {

        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
        }
      }
    ],
  });

  $('.home-service-slider .slide .slide-top').on('click touch', function() {

    $('.home-service-slider .slide').each(function() {
      $(this).find('.slide-top').slideDown();
      $(this).find('.slide-bottom').slideUp();
    });

    $(this).slideUp();
    $(this).parent().find('.slide-bottom').slideDown();
  });



  $('.home-service-slider .slide .slide-bottom a.btn').on('click touch', function(e) {
    
    e.stopPropagation();
  });

  $('.home-service-slider .slide .slide-bottom').on('click touch', function(e) {

    $(this).slideUp();
    $(this).parent().find('.slide-top').slideDown();

  });

  var words = $("#equipe-bottom h2").text().split(" ");
  $("#equipe-bottom h2").empty();
  $.each(words, function(i, v) {
      $("#equipe-bottom h2").append($("<span>").text(v));
  });

  $('.form-slider').slick({
    fade: true,
    arrows: false,
    dots: false,
    adaptiveHeight: true,
  });

  $('.form-choice li').on('click touch', function() {

    if(!$(this).hasClass('active')){
      $('.form-choice li.active').removeClass('active');
      $(this).addClass('active');
    }

    var formId = $(this).attr('data-form');

    $('.form-slider').slick('slickGoTo', formId);

  });

  $('.form-submit .btn').on('click touch', function() {
    $('.form-slider .slick-slide.slick-active form').submit();

    

    
  });

  document.addEventListener( 'wpcf7invalid', function( event ) {
    setTimeout(function() {
      $('.form-slider').find(".slick-slide").height("auto");     
      $('.form-slider').slick("setOption", "", "", true);
    }, 250);
  }, false );



  if(window.location.hash) {
      var hash = window.location.hash.substring(1);

      if(hash == 'rdv' || hash == 'form-intro'){
        $('html, body').animate({
          scrollTop: $('#form-intro').offset().top
        }, 'slow');

        $('.form-choice li.active').removeClass('active');
        $('.form-choice li[data-form="'+1+'"]').addClass('active');

        $('.form-slider').slick('slickGoTo', 1);
      }

      if(hash == 'patient'){
        $('html, body').animate({
          scrollTop: $('#form-intro').offset().top
        }, 'slow');
      }
      // hash found
  }


  //SCROLLREVEAL

  ScrollReveal().reveal('.fade', { delay: 500 });
  ScrollReveal().reveal('.fade-1-no-delay', { delay: 0, distance: '25px', origin: 'top' });
  ScrollReveal().reveal('.fade-2-no-delay', { delay: 250, distance: '25px', origin: 'top' });
  ScrollReveal().reveal('.fade-3-no-delay', { delay: 500, distance: '25px', origin: 'top' });
  ScrollReveal().reveal('.fade-1', { delay: 500, distance: '25px', origin: 'top' });
  ScrollReveal().reveal('.fade-2', { delay: 750, distance: '25px', origin: 'top' });
  ScrollReveal().reveal('.fade-3', { delay: 1000, distance: '25px', origin: 'top' });
  ScrollReveal().reveal('.fade-left', { delay: 500, distance: '25px', origin: 'left' });
  ScrollReveal().reveal('.fade-top', { delay: 500, distance: '100px', origin: 'top' });
  ScrollReveal().reveal('.fade-right', { delay: 500, distance: '25px', origin: 'right' });


  $('body').addClass('loaded');

  var s = skrollr.init({
    forceHeight: false
  });
  if (s.isMobile()) {
      s.destroy();
  } 

      
  }




  window.onload = configurer;

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
